import * as React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { t } from "i18next";

function Disclaimer(): React.ReactElement {
  return (
    <div className="w-full pt-4 pr-4 pl-4 sm:pt-6 sm:pr-6 sm:pl-6 lg:pt-8 lg:pr-8 lg:pl-8">
      <div className="bg-orange/5 border border-orange/20 rounded-xl p-4">
        <div className="flex items-start gap-3">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-orange flex-shrink-0 mt-0.5"
            data-testid="exclamation-triangle-icon"
          />
          <div className="space-y-1">
            <h3 className="font-bold text-dunkelblau text-lg">{t("disclaimer.title")}</h3>
            <p className="text-sm text-gray-600">
              <span>{t("disclaimer.originalContent")}</span>{" "}
              <span>{t("disclaimer.liability")}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Disclaimer;
