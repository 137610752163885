import axios, { AxiosResponse } from "axios";
import { IFilterData, IFilterResponse, IJob } from "../Interfaces/types";
import { IJobList } from "../components/DatabaseTool/JobList";

const URL: string = "http://localhost:4010/api/jobs";
const OPEN_ROUTE_URL: string = "http://localhost:4010/api/openroute";
const TOOL_URL: string = "http://localhost:4010/api/tool/jobs";

/**
 * Fetches Filtered Array of Jobs from the Database.
 * @returns {Promise<IJob[]>}
 * @throws {AxiosError}
 */
export async function fetchJobs(filterData: IFilterData): Promise<IFilterResponse> {
  let specialty: string = remapSpecialty(filterData.userSpecialty);
  const RESPONSE: AxiosResponse = await axios.post<IFilterResponse>(URL, {
    specialty: specialty,
    language: filterData.userLanguage,
    employernames: filterData.userEmployernames,
    duration: filterData.userDuration,
    modenumber: filterData.userMode,
    longitute: filterData.userLongitute,
    latitute: filterData.userLatitute,
    sortmode: filterData.userSortMode,
    sortorder: filterData.userSortOrder,
    searchterm: filterData.userSearchterm,
    page: filterData.userPage,
  });

  return RESPONSE.data;
}

/**
 * Retrieves Address Autocompletion
 */
export async function fetchAutoCompleteAddress(input: string): Promise<AxiosResponse> {
  return await axios.get(`${OPEN_ROUTE_URL}/autocomplete/${input}`);
}

function remapSpecialty(specialty: string) {
  if (!specialty) {
    return "";
  }
  if (specialty == "socialScience") {
    return "Geistes- und Sozialwissenschaften";
  }
  if (specialty == "engineering") {
    return "Ingenieurwissenschaften";
  }
  if (specialty == "culture") {
    return "Kultur, Kunst, Musik";
  }
  if (specialty == "health") {
    return "Medizin, Gesundheit, Psychologie";
  }
  if (specialty == "mint") {
    return "MINT";
  }
  if (specialty == "law") {
    return "Rechtswissenschaften";
  }
  if (specialty == "economics") {
    return "Wirtschaftswissenschaften";
  }
  if (specialty == "nonAcademic") {
    return "Nicht-wissenschaftliche Berufe";
  }
  return "";
}

export async function fetchJosForUser(): Promise<IJobList[]> {
  let response: AxiosResponse = await axios.get<IJobList>(`${TOOL_URL}`);
  return response.data;
}

export async function fetchOneJob(jobid: number): Promise<IJob> {
  let response: AxiosResponse = await axios.get(`${URL}/${jobid}`);
  return response.data;
}
