import * as React from "react";
import { fetchJosForUser } from "../../apiReceive/receiveJob";

export interface IJobList {
  JobID: number;
  Title: string;
  ValidationKey: string;
}

const VALIDATION_URL: string = "http://localhost:3010?key=";

export default function JobList(): React.ReactElement {
  const [SEARCH_TERM, SET_SEARCH_TERM]: [string, React.Dispatch<React.SetStateAction<string>>] =
    React.useState("");
  const [JOBS, SET_JOBS]: [IJobList[], React.Dispatch<React.SetStateAction<IJobList[]>>] =
    React.useState([]);

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    SET_SEARCH_TERM(event.target.value);
  }

  function goToJob(key: string) {
    window.open(`${VALIDATION_URL}${key}`);
  }

  React.useEffect(function () {
    async function fetchData() {
      try {
        let jobs: IJobList[] = await fetchJosForUser();
        SET_JOBS(jobs);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <h3 className="font-semibold text-2xl text-center mb-4">Jobs</h3>
      <div className="w-full flex justify-center mb-8">
        <input
          className="w-4/5 p-2 border border-gray-300 rounded-md shadow"
          placeholder="Suchen..."
          onChange={handleSearchChange}
        />
      </div>
      <div className="flex flex-col gap-4 justify-center">
        {JOBS.map(function (job: IJobList) {
          return (
            (job.Title.toLowerCase().includes(SEARCH_TERM.toLowerCase()) ||
              job.JobID.toString().includes(SEARCH_TERM)) && (
              <button
                key={job.JobID}
                onClick={function () {
                  goToJob(job.ValidationKey);
                }}
                className="flex flex-col bg-white hover:bg-sky-200 border border-gray-400 shadow rounded-xl p-2"
              >
                <h1 className="font-semibold text-m text-left">#{job.JobID}</h1>
                <h1 className="font-semibold text-xl text-left">{job.Title}</h1>
              </button>
            )
          );
        })}
      </div>
    </>
  );
}
