import * as React from "react";
import ShowDuration from "./ActiveFilterElements/ShowDuration";
import ShowLanguage from "./ActiveFilterElements/ShowLanguage";
import ShowSpecialty from "./ActiveFilterElements/ShowSpecialty";
import ShowEmployerNames from "./ActiveFilterElements/ShowEmployerNames";
import { IFilterData } from "../Interfaces/types";
import { FunnelIcon } from "@heroicons/react/20/solid";
import { t } from "i18next";
import { IFilterDataProps } from "../Interfaces/props";
import ShowSearchterm from "./ActiveFilterElements/ShowSearchterm";

export default function ActiveFilters({
  filterData,
  setFilterData,
}: IFilterDataProps): React.ReactElement {
  return (
    <>
      {hasActiveFilter(filterData) && (
        <div className="w-full p-3 bg-hellblau/5 mt-4 rounded-xl border border-hellblau/20">
          <div className="flex items-center gap-2 mb-2">
            <FunnelIcon className="h-4 w-4 text-dunkelblau/60" />
            <span className="text-sm text-dunkelblau/80 font-medium">
              {t("searchBar.actFilter")}
            </span>
          </div>
          <div className="flex flex-wrap gap-2">
            <ShowLanguage filterData={filterData} setFilterData={setFilterData} />
            <ShowDuration filterData={filterData} setFilterData={setFilterData} />
            <ShowSpecialty filterData={filterData} setFilterData={setFilterData} />
            <ShowEmployerNames filterData={filterData} setFilterData={setFilterData} />
            <ShowSearchterm filterData={filterData} setFilterData={setFilterData} />
          </div>
        </div>
      )}
    </>
  );
}

function hasActiveFilter(filterData: IFilterData): boolean {
  if (Number(filterData.userDuration) !== 0) {
    return true;
  }

  if (filterData.userEmployernames.length > 0) {
    return true;
  }

  if (filterData.userLanguage) {
    return true;
  }

  if (filterData.userSpecialty) {
    return true;
  }

  if (filterData.userSearchterm) {
    return true;
  }

  return false;
}
