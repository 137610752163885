import * as React from "react";
import { IoMdWalk } from "react-icons/io";
import { FaCarSide } from "react-icons/fa";
import { IoMdBicycle } from "react-icons/io";

interface ICommuteButtonsProps {
  selectedButton: number;
  setSelectedButton: React.Dispatch<React.SetStateAction<number>>;
}

export default function CommuteButtons({
  selectedButton,
  setSelectedButton,
}: ICommuteButtonsProps) {
  const BUTTONS: { icon: React.ReactNode }[] = [
    { icon: <IoMdWalk size={35} /> },
    { icon: <IoMdBicycle size={40} /> },
    { icon: <FaCarSide size={40} /> },
  ];

  return (
    <div className="flex items-center space-x-5 py-2 px-4 border rounded-xl mt-2">
      {BUTTONS.map(function (button: { icon: React.ReactNode }, index: number) {
        return (
          <button
            key={index}
            onClick={function () {
              setSelectedButton(index);
            }}
            className={`p-2 transition-all duration-300 ease-in-out transform ${
              selectedButton === index
                ? "text-yellow-400 scale-110"
                : "text-blue-500 hover:text-yellow-400 hover:scale-105"
            }`}
            aria-label="commute mode"
          >
            {button.icon}
          </button>
        );
      })}
    </div>
  );
}
