import * as React from "react";
import { useState } from "react";
import CommuteButtons from "./CommuteBarElements/CommuteButtons";
import CommuteBarMinuteField from "./CommuteBarElements/CommuteBarMinuteField";
import CommuteBarAddressField from "./CommuteBarElements/CommuteBarAddressField";
import { ICommuteBarProps } from "../Interfaces/props";
import { t } from "i18next";
import { IFilterData } from "../Interfaces/types";

export default function CommuteBar({ setFilterData }: ICommuteBarProps) {
  const [USER_LONGITUTE, SET_USER_LONGITUTE]: [
    string,
    React.Dispatch<React.SetStateAction<string>>,
  ] = useState<string>("");
  const [USER_LATITUTE, SET_USER_LATITUTE]: [string, React.Dispatch<React.SetStateAction<string>>] =
    useState<string>("");
  const [MINUTE_VALUE, SET_MINUTE_VALUE]: [
    number | string,
    React.Dispatch<React.SetStateAction<number | string>>,
  ] = useState<number | string>(0);
  const [SELECTED_BUTTON, SET_SELECTED_BUTTON]: [
    number,
    React.Dispatch<React.SetStateAction<number>>,
  ] = React.useState<number>(0);

  async function handleFilterClick() {
    if (!USER_LATITUTE || !USER_LONGITUTE) {
      return;
    }
    try {
      setFilterData(function (prevData: IFilterData) {
        return {
          ...prevData,
          userLatitute: USER_LATITUTE,
          userLongitute: USER_LONGITUTE,
          userMode: SELECTED_BUTTON,
          userDuration: MINUTE_VALUE,
          userPage: 0,
        };
      });
    } catch {
      console.log("Filter Anwenden Click Failed");
    }
  }

  return (
    <div className="p-2 w-72 h-auto bg-white border-gray-300 shadow-lg rounded-xl flex flex-col items-center">
      <h1 className="text-center text-dunkelblau pb-4 pt-3 font-bold text-xl">
        {t("commuteBar.commuteTime")}
      </h1>

      <CommuteBarAddressField
        setUserLatitute={SET_USER_LATITUTE}
        setUserLongitute={SET_USER_LONGITUTE}
      />
      <h1 className="text-center text-gray-600 p-2 pb-1">{t("commuteBar.maxCommuteTime")}</h1>
      <CommuteBarMinuteField minuteValue={MINUTE_VALUE} setMinuteValue={SET_MINUTE_VALUE} />
      <CommuteButtons selectedButton={SELECTED_BUTTON} setSelectedButton={SET_SELECTED_BUTTON} />
      <button
        onClick={handleFilterClick}
        className="p-3 pl-4 pr-4 mt-5 mb-4 rounded-xl bg-orange text-white hover:bg-orange/90 text-center text-white transition-all duration-300 ease-in-out transform"
      >
        {t("commuteBar.applyFilters")}
      </button>
    </div>
  );
}
